import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu } from './SidebarElements'

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="home" smooth={true} duration={500} spy={true} exact='true' offset={-80} onClick={toggle}>
                        Home
                    </SidebarLink>
                    <SidebarLink to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80} onClick={toggle}>
                        About
                    </SidebarLink>
                    <SidebarLink to="info" smooth={true} duration={500} spy={true} exact='true' offset={-80} onClick={toggle}>
                        Services
                    </SidebarLink>
                    <SidebarLink to="contact" smooth={true} duration={500} spy={true} exact='true' offset={-80} onClick={toggle}>
                        Contact Us
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar