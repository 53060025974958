import React from 'react'
import { HeroContainer, HeroWrapper, HeroRow, Column1, Column2, TextWrapper, Heading, Subtitle, ImgWrap, Img} from './HeroElements'
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import Icon1 from '../../images/story.png'

const HeroSection = () => {

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className={"container-style"}>
    <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: { enable: true, zIndex: 0 },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 100,
                duration: 0.5,
              },
            },
          },
          particles: {
            color: {
              value: "008080",
            },
            links: {
              color: "008080",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              directions: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 40,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 6 },
            },
          },
          detectRetina: true,
        }}
      />
      <HeroContainer id='home'>
        <HeroWrapper>
          <HeroRow imgStart='true'>
            <Column1>
              <TextWrapper>
                <Subtitle darkText='true'>Creativity opens the mind.
                </Subtitle>
                <Subtitle darkText='true'>Creativity is about taking the most ordinary of ideas and transforming them into the most extraordinary experiences.
                </Subtitle>
                <Subtitle darkText='true'>We believe in embracing Creativity beyond Art, Literature and the Performing Arts into Story telling, Content Creation and Social Communication.
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={Icon1} alt='temp' />
              </ImgWrap>
            </Column2>
          </HeroRow>
        </HeroWrapper>
      </HeroContainer>
    </div>
  )
}

export default HeroSection