import styled from 'styled-components'

export const ContactContainer = styled.div`
    color: #fff;
	height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    margin-bottom: 50px;
    margin-top: 80px;

    @media screen and (max-width: 768px){
        height: 100%;
        padding: 20px 15px 0px 15px;
    }

    @media screen and (max-width: 480px){
        height: 100%;
        padding: 20px 15px 0px 15px;
    }

    @media screen and (max-width: 390px){
        height: 100%;
        padding: 20px 15px 0px 15px;
    }
`

export const ContactWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 650px;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;	
`

export const ContactRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;    
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    align-items: center; 
	
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    align-items: center; 
`
export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color:#fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;  
`

export const Heading = styled.h1`

    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`

export const Subtitle = styled.p`
    max-width: 540px;
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    align-items: center; 
`

export const Subtitle1 = styled.p`
    max-width: 540px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    align-items: center; 
`

export const Addr1 = styled.p`
    max-width: 600px;
    padding-left: 30px;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 24px;
    color: #000000;
`
export const Addr = styled.p`
    max-width: 600px;
    padding-left: 30px;
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
`


export const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 15px;

`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`
export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`
export const Label = styled.label`
  align-items: center;
  display: flex;
  gap: 8px;
  color: #000;
`

export const Form = styled.form`
	background: #E0E0E0;
	max-width: 100%;
	height: auto;
	width:	100%;
	z-index: 1;
	display: grid;
	margin: 0 auto;
	padding: 80px 32px;
@media screen and (max-width: 400px){
		padding: 32px 32px;
}
`;

export const Input = styled.input`
	padding: 12px 12px;
	margin-bottom: 32px;
	width: 100%;
	border: none;
	border-radius: 4px;
	border: 1px solid black;
`;

export const Textarea = styled.textarea`
    width: 100%;
	margin-bottom: 20px;
`;
