import React, { useState } from 'react'
import { Body } from './PageElements.js';
import HeroSection from '../../components/HeroSection'
import About from '../../components/About'
import Footer from '../../components/Footer'
import MainService from '../../components/MainService'
import ContactService from '../../components/ContactUsSection'
import Navbar from '../../components/Navbar'
import Sidebar from '../../components/Sidebar'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
      setIsOpen(!isOpen)
    }
    return (
      <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <Body>
          <HeroSection />
          <About />
          <MainService />
          <ContactService />
        </Body>
        <Footer />
      </>
    )
  }
  
  export default Home