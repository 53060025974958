import React, { useState, useEffect } from 'react'
import { Nav, NavbarContainer, MobileIcon, NavItems, NavMenu, NavLinks, ImgWrap, Img } from "./NavbarElements";
import { FaBars } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import Icon1 from '../../images/logo-solid-blue.png' 
// import { animateScroll as scroll } from 'react-scroll'


function Navbar({ toggle }) {

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    // const toggleHome = () => {
    //     scroll.scrollToTop()
    // }
    return (
        <>
            <IconContext.Provider value={{ color: '#ffffff' }}>
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>                        
                        <ImgWrap>
                            <Img src={Icon1} alt='temp' />
                        </ImgWrap>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItems>
                                <NavLinks to="home" smooth={true} duration={500} spy={true} exact='true' offset={0}>Home</NavLinks>
                            </NavItems>
                            <NavItems>
                                <NavLinks to="about" smooth={true} duration={500} spy={true} exact='true' offset={0}>About</NavLinks>
                            </NavItems>
                            <NavItems>
                                <NavLinks to="info" smooth={true} duration={500} spy={true} exact='true' offset={0}>Services</NavLinks>
                            </NavItems>
                            <NavItems>
                                <NavLinks to="contact" smooth={true} duration={500} spy={true} exact='true' offset={-40}>Contact</NavLinks>
                            </NavItems>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar