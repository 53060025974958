import React, { useRef } from 'react'
import { ContactContainer, ContactRow, Column1, Column2, TopLine, Heading, Subtitle, Subtitle1,BtnWrap, Input, Textarea, Addr, Addr1 } from './ContactUsElements'
import emailjs from '@emailjs/browser'
import { ContactButton } from '../ButtonElements'


const ContactUsSection = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); 
    if (form.current[0].value === "" || form.current[2].value === "" || form.current[3].value === "" || form.current[4].value === "") {
      console.log("ERROR");
    }
    else {
      emailjs.sendForm('service_jzbxn0m', 'template_4zuwqpi', form.current, 'ubIrk7VLTM2hFKLem')
        .then((result) => {
          console.log(result.text);
          console.log(form.message);
        }, (error) => {
          console.log(error.text);
        });
        var form1 = document.getElementById("contact_form");
        form1.reset();
    }
  }



  return (

    <>
      <ContactContainer lightBg={true} id={'contact'}>   
        <Heading lightText={false}>Contact Us!</Heading>
        <form id="contact_form" ref={form} onSubmit={sendEmail}>
          <ContactRow imgStart={true}>
              <Subtitle darkText={true}>Queries, Feedback and Suggestions are welcome! </Subtitle>
              <Input type="text" name="user_name" placeholder='Name'/>
              <Input type="text" name="user_number" placeholder='Number'/>
              <Input type="text" name="user_org" placeholder='Organisation'/>
              <Input type="email" name="user_email" placeholder='Email'/>
              <Textarea name="message" placeholder='Type your message here...'/>
              <BtnWrap>
                <ContactButton type="submit" smooth={true} duration={500} spy={true} exact="true" offset={-80} primary={1} dark={false} dark2={0} >Submit</ContactButton>
              </BtnWrap>
          </ContactRow>
        </form>
        <Subtitle1 darkText={true}>+91 98400 68525 | +91 98402 00812</Subtitle1>
        <Subtitle darkText={true}>info@tessworks.org</Subtitle>
      </ContactContainer>
    </>

  )
}

export default ContactUsSection
