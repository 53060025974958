import styled from 'styled-components'
import { Link as LinkR} from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

// #594586
export const Nav = styled.nav`
    background: #008080;
    height: 80px;    
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }

    @media screen and (max-width: 960px){
        height: 70px;
        margin-top: -70px;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    // padding: 0 24px;
    max-width: 97%;
`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    // margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    
`
export const NavImg=styled.img`
    ;`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width:768px){
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #fff;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: flex-end;
    list-style: none;
    justify-content: right;
    text-align: center;
    // margin-right: -22px;

    @media screen and (max-width: 768px){
      display: none;
  }
`;

export const NavItems = styled.li`
  height: 80px;
`

export const NavLinks = styled(LinkS)`
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

`

export const ImgWrap = styled.div`
    display: flex;
    align-items: center;
    max-width: 150px;
    height: 100%;

    @media screen and (max-width: 768px){
        max-width: 80px;
    }

    @media screen and (max-width: 480px){
        max-width: 100px;
    }

`
export const Img = styled.img`
    width: 100%;
    margin: 10px 0px 10px 10px;
    padding-right: 0;

    @media screen and (max-width: 480px){
        margin: 15px 0px 10px 10px;
    }

`