import styled from 'styled-components'

export const MainSContainer = styled.div`
    id:info;
    height: 500vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    margin-top: 80px;

    @media screen and (max-width: 768px){
        height: 500%;
    }

    @media screen and (max-width: 480px){
        height: 500%;
    }

`

export const MainSH1 = styled.h1`
    font-size: 2.5rem;
    color: #0b0b0b;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`
export const MainSWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 100%;
    width: 100%;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 12spx;
    justify-content: center;
    margin-bottom: auto;
`

export const MainSRow1 = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => imgStart ? `'col1 col2'` : `'col1 col2'`};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({ imgStart }) => imgStart ? `'col2' 'col1'` : `'col2' 'col1'`}
    }
`
export const MainSRow2 = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({ imgStart }) => imgStart ? `'col2' 'col1'` : `'col2' 'col1'`}
    }
`


export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;    
    align-items: center; 
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
    align-items: center; 
`
export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
`

export const TopLine = styled.p`
    color:#fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;  
`

export const Heading = styled.h1`

    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};
`

export const Sub = styled.h1`
    font-size: 18px;
    line-height: 24px;
    color: #008080;
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`
export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`