import React from 'react'

import { MainSContainer, MainSWrapper, MainSRow1, MainSRow2, Column1, Column2, TextWrapper, Heading, Subtitle, Sub, Img, ImgWrap } from './MainSElements.js'
import Icon1 from '../../images/ar.png'
import Icon2 from '../../images/elearning.png'
import Icon3 from '../../images/explainervideos.png'
import Icon4 from '../../images/lms2.png'
import Icon5 from '../../images/k12.png'
import file1 from '../../images/ListofCourses.pdf'
import file2 from '../../images/K12Details.pdf'

const MainS = () => {
  return (
    <MainSContainer id='info'>
      <MainSWrapper>
        <MainSRow1 imgStart='true'>
          <Column1>
            <TextWrapper>
              <Heading darkText='true'>Creative Explainer Videos</Heading>
              <Subtitle darkText='true'>Explainer Videos are the go-to solution when you need to keep your communication short , yet engaging and impactful.
              </Subtitle>
              <Subtitle darkText='true'>
              Try them out for your next marketing or promo videos about your upcoming  product , service or internal policy communication.
              </Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={Icon3} alt='temp' />
            </ImgWrap>
          </Column2>
        </MainSRow1>
      </MainSWrapper>

      <MainSWrapper>
        <MainSRow2 imgStart='true'>
          <Column1>
            <TextWrapper>
              <Heading darkText='true'>E-learning Modules</Heading>
              <Subtitle darkText='true'>E-Learning is here to stay because of the tremendous benefits it offers as a training tool in terms of efficiency, scalability and anytime-availability. 
              </Subtitle>
              <Subtitle darkText='true'>
              With our expertise in the latest rapid authoring tools we can considerably bring down development time and costs. We can also help you cater to both an Indian and an International audience. 
              </Subtitle>
              <Subtitle darkText='true'>
              Our know-how includes but is not limited to: <Sub>Environment, Health & Safety, Professional & New Age Skills, Management & Leadership Skills.</Sub> For more details, click <a href={file1} target="_blank" download="ListOfCourses">here</a>
              </Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={Icon2} alt='temp' />
            </ImgWrap>
          </Column2>
        </MainSRow2>
      </MainSWrapper>

      <MainSWrapper>
        <MainSRow1 imgStart='true'>
          <Column1>
            <TextWrapper>
              <Heading darkText='true'>Meta/VR/AR Modules</Heading>
              <Subtitle darkText='true'>
                The Metaverse is the next iteration of the internet: a single, shared, immersive world facilitated by the use of VR and AR headsets.
              </Subtitle>
              <Subtitle darkText='true'>
              One strongly emerging area is its use in training- It gives employees the chance to gain on-the-job training in a risk-free environment where they can learn by doing.
              </Subtitle>
              <Subtitle darkText='true'>
              So why not try something new?
              </Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={Icon1} alt='temp' />
            </ImgWrap>
          </Column2>
        </MainSRow1>
      </MainSWrapper>

      <MainSWrapper>
        <MainSRow2 imgStart='true'>
          <Column1>
            <TextWrapper>
              <Heading darkText='true'>LMS on the Cloud</Heading>
              <Subtitle darkText='true'>Learning Management Systems – Regardless of whether you are a fledgling organisation or a stalwart with 1000s of employees we extend services of a Learning Management System on the cloud as a scalable SAAS application. 
              </Subtitle>
              <Subtitle darkText='true'>
              Let us help you manage your training needs and performance seamlessly.
              </Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={Icon4} alt='temp' />
            </ImgWrap>
          </Column2>
        </MainSRow2>
      </MainSWrapper>

      <MainSWrapper>
        <MainSRow1 imgStart='true'>
          <Column1>
            <TextWrapper>
              <Heading darkText='true'>Solutions for K-12</Heading>
              <Subtitle darkText='true'>
              Solutions for Schools that focusses on supporting the learning process at all development stages of the child. </Subtitle>
              <Subtitle darkText='true'>
              Tools to support Teachers in effectiveness and adapting to the new-age learner. Supporting the School Management and parents to help bring out the Childs’ full potential.</Subtitle>
              <Subtitle darkText='true'>
              We provide a wide range of curriculum resources, professional development opportunities for the teachers, tools for tracking student performance, communication and collaboration tools.
              </Subtitle>
              <Subtitle darkText='true'>
              We also offer some onsite/offsite sessions on new age emerging trends. Click <a href={file2} target="_blank" download="K12_Details">here</a>
              </Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={Icon5} alt='temp' />
            </ImgWrap>
          </Column2>
        </MainSRow1>
      </MainSWrapper>

    </MainSContainer >
  )
}

export default MainS
