import { initializeApp } from "firebase/app";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'


const firebaseConfig = {

  apiKey: "AIzaSyAJiAexq9tYbqimW-vINjcyQqZWC6TlX-I",
  authDomain: "tesstest-c921d.firebaseapp.com",
  projectId: "tesstest-c921d",
  storageBucket: "tesstest-c921d.appspot.com",
  messagingSenderId: "612281011825",
  appId: "1:612281011825:web:abdca0a13923efbaced5d0",
  measurementId: "G-4RYR3XDVCT"

};

const app = initializeApp(firebaseConfig);


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
      </Routes>
    </Router>
  );
}

export default App;
