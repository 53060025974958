import React from 'react'
import Icon1 from '../../images/tree.png' 

import { AboutContainer, AboutWrapper, AboutRow, Column1, Column2, TextWrapper, Heading, Subtitle, ImgWrap, Img} from './AboutElements'

const About = () => {
  return (
    <>
      <AboutContainer lightBg='true' id='about'>
        <AboutWrapper>
          <AboutRow imgStart='true'>
            <Column1>
              <TextWrapper>
                <Heading darkText='true'>About us</Heading>
                <Subtitle darkText='true'>Tessworks Global is a young startup founded in 2023.
                </Subtitle>
                <Subtitle darkText='true'>  We have cumulative experience of over a hundred years at the helm in Education technology, Instructional design, Content writing & development, Creative design and Design Thinking.
                </Subtitle>
                <Subtitle darkText='true'>We enjoy finding innovative solutions for your learning and communication needs.
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={Icon1} alt='temp' />
              </ImgWrap>
            </Column2>
          </AboutRow>
        </AboutWrapper>
      </AboutContainer>
    </>
  )
}

export default About
